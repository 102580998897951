import React from "react";

import "../style.css"

const Header = () => {
    return (
        <section className="main-content">
            <div className="content-wrapper">
                <h1>The future of mobile security</h1>
                
                <div className="info-text">
                    <p>
                        Skyforce Software is at the cutting edge of Android anti-cheat technology. 
                        We are on a mission with a strong dedication to secure mobile gaming spaces.
                        Today, our experienced team is tirelessly working to outsmart cheaters and 
                        protect the integrity of Android games.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Header