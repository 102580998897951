import React, { useEffect } from "react";
import "../style.css"

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import WOW from "wowjs"
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
    //Initialize WOWJS
    useEffect(()=>{
        new WOW.WOW().init();
    }, [])

    return (
        <div className="layout">
            <Navbar />

            <ContactForm />

            <Footer />
        </div>
    )
}

export default ContactPage