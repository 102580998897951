import React from "react";

import "../style.css"

const Services = () => {
    return (
        <section className="content-section" id="work">
            <div className="content-title">
                <h2>Our Work</h2>
                <p>We're dedicated to developing security solutions that work at scale. We commit to maximize three things during development:</p>
            </div>

            <div className="content-services">
                <div className="content-card">
                    <div className="content-icon">
                        <i className="fas fa-calendar"></i>
                    </div>
                    <h2>Reliability</h2>
                    <p>
                        Our products offer robust security in anti-cheat solutions, employing advanced tech and frequent updates for fair gaming without compromise.
                    </p>
                </div>
                <div className="content-card">
                    <div className="content-icon">
                        <i className="fas fa-calendar"></i>
                    </div>
                    <h2>Security</h2>
                    <p>
                        In mobile gaming, our products are a security fortress, using cutting-edge tech to protect players from external threats and ensure a secure gaming experience.
                    </p>
                </div>
                <div className="content-card">
                    <div className="content-icon">
                        <i className="fas fa-calendar"></i>
                    </div>
                    <h2>Performance</h2>
                    <p>
                        In mobile gaming, performance is paramount. Our solutions ensure lightning-fast, smooth gameplay for an optimized gaming experience without hitches.
                    </p>
                </div>
            </div>
        </section>      
    )
}

export default Services