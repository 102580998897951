import React from "react";

import "../style.css"

const Products = () => {
    return (
        <section className="content-section" id="work">
            <div className="content-title" id="content-title-products">
                <h2>Our Products</h2>
            </div>

            <div className="content-services">
                <div className="content-card" id="content-card-products">
                    <div className="content-icon">
                        <i className="fas fa-calendar"></i>
                    </div>
                    <h2>Skyforce Anti-Cheat</h2>
                    <p>
                        Skyforce Anti Cheat delivers advanced android protection, ensuring fair and secure mobile gaming with our experienced team continuously outsmarting cheaters to uphold game integrity.
                    </p>
                    <a href="https://skyforce.ac">Learn More</a>
                </div>
            </div>
        </section>      
    )
}

export default Products