import React, { useRef } from "react";

import "../style.css"

const Navbar = () => {
    const hamburger = useRef();
    const navList = useRef();
    const navList2 = useRef();

    const showNavbar = () => {
        navList.current.classList.toggle("active");
        navList2.current.classList.toggle("active");

        // Check and remove the 'active' className when resizing the window
        window.addEventListener('resize', () => {
            if (window.innerWidth > 768 && navList.current.classList.contains('active')) {
                hideNavbar();
            }
        });
    }

    const hideNavbar = () => {
        navList.current.classList.remove('active');
        navList2.current.classList.remove('active');
    }

    return (
        <nav class="navbar">
            <div className="brand">
                <a href="/">Skyforce Software</a>
            </div>
            <div class="nav-list" ref={navList2}>
                <ul class="nav-list2" ref={navList}>
                    <li><a onClick={() => hideNavbar()} href="/">Home</a></li>
                    <li><a onClick={() => hideNavbar()} href="/#work">Our Work</a></li>
                    <li><a onClick={() => hideNavbar()} href="/contact">Contact</a></li>
                    <li><a onClick={() => hideNavbar()} href="/#about-us">About</a></li>
                </ul>
            </div>
            <div className="hamburger" ref={hamburger} onClick={showNavbar}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </nav>        
    )
}

export default Navbar