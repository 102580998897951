import React from "react";

const About = () => {
    return (
        <section className="about-us wow fadeIn" id="about-us">
            <div className="heading">
                <h1>About Us</h1>
            </div>
            <div className="about-container">
                <section className="about">
                    <div className="about-content">
                        <p>
                            Our team consists of two passionate individuals dedicated to securing online games against cheaters. 
                            With a shared background in game development and a deep understanding of cheat behavior, 
                            we've developed an innovative anti-cheat solution that uses advanced technology to detect and prevent cheats in real-time. 
                            Our goal is to help create a fair and enjoyable gaming environment for all players.
                        </p>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default About