import React, { useEffect } from "react";
import "../style.css"

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Contact from "../components/Contact";
import About from "../components/About";
import Footer from "../components/Footer";

import WOW from "wowjs"
import Products from "../components/Products";

const Home = () => {
    //Initialize WOWJS
    useEffect(()=>{
        new WOW.WOW().init();
    }, [])

    return (
        <div className="layout">
            <Navbar />
            
            <Header />
            <Services />
            <About />
            <Products />
            <Contact />

            <Footer />
        </div>
    )
}

export default Home