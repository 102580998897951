// LoadingButton.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingButton = ({ buttonType, isLoading }) => {
    return (
        <button type={buttonType} disabled={isLoading} style={{ width: '100%' }}>
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
        </button>
    );
};

export default LoadingButton;
