import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

import "../style.css"

const Footer = () => {
    return (
        <section className="footer">
            <div className="social">
                <a href="https://www.youtube.com/@Skyforce-AC" target="_blank"><FontAwesomeIcon icon={faYoutube}/></a>
                <a href="https://twitter.com/SkyforceAC" target="_blank"><FontAwesomeIcon icon={faTwitter}/></a>
            </div>

            <ul className="list">
                <li><a href="/">Home</a></li>
                <li><a href="/#work">Our Work</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/#about">About</a></li>
            </ul>
            <p className="copyright"><a href="https://skyforcesoftware.com">Skyforce Software</a> &copy;2023</p>
        </section>
    )
}

export default Footer