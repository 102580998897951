import React from 'react';

import Home from "./pages/Home"
import ContactPage from './pages/ContactPage';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { render } from "react-dom";

const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path='/contact' element={<ContactPage/>} />
        </Routes>
    </BrowserRouter>,
    rootElement
);