import React from "react";

const Contact = () => {
    return (
        <section className="contact-new wow fadeIn" id="about-us">
            <div className="contact-new-container">
                <section className="about">
                    <div className="about-content">
                        <h2>Interested in working with us? <br /> Contact Us</h2>
                        <p>
                            If you are a game developer or publisher interested in integrating our products into your game please don't hesitate to contact us. <br /> General questions and comments are welcome
                        </p>
                        <div className="contact-center">
                            <a href="/contact" className="read-more">Contact Us</a>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default Contact